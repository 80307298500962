import {
  getExamById,
  getTasksFromExam,
} from "components/Admin/utils/elementGetters";
import { Task, Exam, Item } from "services/admin/Interfaces/Types";
import { TeacherItemAssignment } from "store/reducers/UsuarioAplicacaoProva/AsyncActions/types";

export const getTaskItemsAlocados = (
  vinculacao: TeacherItemAssignment,
  exams: Exam[],
  tasks: Task[]
): Task[] => {
  let tasksAlocacao: Task[] = [];
  const exam: Exam | undefined = getExamById(
    exams,
    vinculacao.exam.id as number
  );
  if (exam) {
    tasksAlocacao = getTasksFromExam(exam, tasks);
  }
  return tasksAlocacao;
};

export const getDetalheVinculacao = (
  vinculacao: TeacherItemAssignment,
  exams: Exam[],
  tasksRef: Task[]
) => {
  let descricao: string | undefined = undefined;
  const tasks: Task[] = getTaskItemsAlocados(vinculacao, exams, tasksRef);
  for (let task of tasks) {
    let prefix = task.title;
    let items: Item[] | undefined = task.items.filter((i) => {
      return i.id === vinculacao.item;
    });

    if (items !== undefined && items.length > 0) {
      descricao = prefix + " > " + items[0].title;
      break;
    }
  }

  return descricao;
};
