import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CardExamRegister.scss";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import CardBase from "./CardBase";

import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  processExamUpdate,
  registerExam,
  registerExamAndWithAssessment,
} from "store/reducers/Admin/AsyncActions/Exam";
import { findKnowledges } from "store/reducers/Admin/AsyncActions/KnowledgesArea";

import { validateFormData } from "../validators/examValidator";

import {
  Exam,
  ExamFormData,
  KnowledgeArea,
  Page,
  SelectedElement,
} from "services/admin/Interfaces/Types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

export default function CardExam() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(findKnowledges());
  }, [dispatch]);

  const [tabControl, setTabControl] = useState("1");

  const knowledgeAreas: Page<KnowledgeArea> = useSelector(
    (state: any) => state.admin
  ).knowledgeAreas;

  const user: IRegisterProps = useSelector(
    (state: any) => state.authentication
  ).userData;

  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  let dataForm: ExamFormData = {
    ...selectedElement.elementDataForm,
  } as ExamFormData;

  let initialDataForm = {...selectedElement.elementDataFormInitial} as ExamFormData
  const [keysChanged, setKeysChanged] = useState<string[]>([])

  function identifyChangeOnValue(key: string, value: unknown) {
    if (!!initialDataForm[key] && String(initialDataForm[key]).trim() !== String(value).trim()) {
      setKeysChanged(prevState => [...prevState, key])
      return
    }
    
    if (!initialDataForm[key] && !!String(value).trim()) {
      setKeysChanged(prevState => [...prevState, key])
      return
    }

    setKeysChanged(prevState => (prevState.filter(prev => prev !== key)))
  }

  const handleDataForm = (key: string, value: unknown) => {
    dataForm[key] = value;
    selectedElement.elementDataForm = dataForm;
    dispatch(setSelectedElement(selectedElement));

    identifyChangeOnValue(key, value)
  };

  const buildExamFromForm = (): Exam => {
    let exam: Exam = {
      id: dataForm.id,
      code: dataForm.code,
      description: dataForm.description,
      published: dataForm.published,
      tasks: dataForm.tasks,
      knowledge_area: dataForm.knowledge_area,
      student_exam: [],
      title: dataForm.title === "" ? undefined : dataForm.title,
      created_by:
        dataForm.created_by === undefined ? user.id : dataForm.created_by,
    };

    return exam;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // console.log(newValue);
    setTabControl(newValue + "");
  };

  const generateSelectKnowledges = () => (
    <Select
      value={dataForm.knowledge_area}
      label="Área do Conhecimento"
      sx={{ background: "white" }}
      onChange={(e) => handleDataForm("knowledge_area", e.target.value)}
    >
      {knowledgeAreas.results !== undefined
        ? knowledgeAreas.results.map((i, key) => (
            <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
              {i.name}
            </MenuItem>
          ))
        : null}
    </Select>
  );

  return (
    <CardBase
      actionRegister={() =>
        registerExamAndWithAssessment(
          buildExamFromForm(),
          selectedElement.parentElement as number
        )
      }
      title={dataForm.id === undefined ? "Inserir Novo Exame" : "Exame"}
      actionUpdate={() => processExamUpdate(buildExamFromForm())}
      validateMethod={() => validateFormData(dataForm)}
      dataForm={dataForm}
      postSubmitRedirectUrl="/editor"
      showActionButtons={true}
      disabledActionButtonsUpdate={keysChanged.length <= 0}
      editorContent={true}
      successButtonText="Inserir"
    >
      <Box
        key="root_content_card_exam"
        display="flex"
        flexDirection="column"
        rowGap="1rem"
      >
        {/* <TabContext value={tabControl}> */}
        {/* <TabList
            sx={{ background: "var(--neutral-1)" }}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
          > */}
        {/* <Tab
              label={"Dados Gerais"}
              value="1"
              className={tabControl === "1" ? "selectedTab" : ""}
            />
            <Tab
              label={"Tarefas"}
              value="2"
              className={tabControl === "2" ? "selectedTab" : ""}
            />
          </TabList> */}

        {/* <TabPanel value="1"> */}
        <Box>
          <FormControl fullWidth>
            <label htmlFor="code" className="exam-label required-symbol">
              Código
            </label>
            <TextField
              id="code"
              value={dataForm.code}
              onChange={(e) => handleDataForm("code", e.target.value)}
              sx={{ background: "white" }}
              inputProps={{ maxLength: 10 }}
              required
              placeholder="Digite o código do exame..."
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth>
            <label htmlFor="title" className="exam-label required-symbol">
              Título
            </label>
            <TextField
              id="title"
              value={dataForm.title}
              onChange={(e) => handleDataForm("title", e.target.value)}
              sx={{ background: "white" }}
              placeholder="Digite o título do exame..."
            />
          </FormControl>
        </Box>

        <Box>
          <FormControl fullWidth>
            <label htmlFor="description" className="exam-label required-symbol">
              Descrição
            </label>
            <TextField
              id="description"
              value={dataForm.description}
              onChange={(e) => handleDataForm("description", e.target.value)}
              sx={{ background: "white" }}
              placeholder="Digite a descrição do exame..."
              multiline
              rows={4}
            />
          </FormControl>
        </Box>

        <Box p={1}>
          <FormControl fullWidth>
            <InputLabel>Área do Conhecimento</InputLabel>
            {generateSelectKnowledges()}
          </FormControl>
        </Box>
        {/* </TabPanel> */}
        {/* <TabPanel value="2">
            <CardBindExamTask
              dataForm={dataForm}
              handleDataForm={handleDataForm}
            /> */}
        {/* </TabPanel> */}
        {/* </TabContext> */}
      </Box>
    </CardBase>
  );
}
