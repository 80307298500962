import { ItemBank } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class BankServices {
  public static async fetch(props?: any) {
    return api.get("items/itembanks/", { params: { ...props } });
  }

  public static async register(props: ItemBank) {
    return api.post("/items/itembanks/", JSON.stringify(props), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async update(props: ItemBank) {
    return api.patch(
      "items/itembanks/" + props.id + "/",
      JSON.stringify(props),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  public static async delete(props: ItemBank) {
    return api.delete("/items/itembanks/" + props.id + "/");
  }
}
