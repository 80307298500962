import {
  ElementTypes,
  IReorderItemRequest,
  IReorderRequest,
  Item,
  SelectedElement,
  Task,
} from "services/admin/Interfaces/Types";
import { resetClickedElements } from "../../Actions/treeView/actions";

import { getTaskById, getTasks } from "../Tasks";
import ItensServices from "./itens.service";
import ContentServices from "../content/contents.service";
import { toast } from "react-toastify";
import { fetchContent } from "../content";
import { findExams } from "../Exam";
import { number } from "zod";
import { getAllItem, removeItem } from "../../Actions/Itens/actions";

export const registerItem = (
  props: Item,
  elementSelected?: SelectedElement
) => {
  return (dispatch: any) => {
    ItensServices.registerItem(props)
      .then(({ data }: any) => {
        const item: Item = data;
        dispatch(getAllItem([item]));

        dispatch(resetClickedElements());
        if (props.task) dispatch(getTaskById(props.task as number));
      })
      .catch((e) => {
        if (e.response.data.task) {
          if (String(e.response.data.task[0]).includes("Invalid pk")) {
            toast.error("Necessário informar uma tarefa válida");
          }
        } else {
          toast.error("Ocorreu um erro durante a criação do item");
        }
      });
  };
};

export const processItemUpdate = (
  props: Item,
  elementSelected?: SelectedElement
) => {
  return (dispatch: any) => {
    ItensServices.updateItem(props)
      .then(({ data }: any) => {
        dispatch(getAllItem([data]));
        if (data.id) {
          dispatch(resetClickedElements());
          if (props.task) dispatch(getTaskById(props.task as number));
          toast.success("Item atualizado com sucesso!");
        } else {
          toast.error("Ocorreu um erro durante a atualização do item!");
        }
      })
      .catch(() =>
        toast.error("Ocorreu um erro durante a atualização do item!")
      );
  };
};

export const processItemDelete = (
  props: Item,
  successCallBack: any,
  errorCallBack
) => {
  return (dispatch: any) => {
    ItensServices.deleteItem(props)
      .then(({ data }: any) => {
        dispatch(resetClickedElements());
        dispatch(removeItem(props.id as number));
        if (props.task) dispatch(getTaskById(props.task as number));
        successCallBack();
      })
      .catch(() => errorCallBack());
  };
};

export const moveItemInsideTask = (props: IReorderRequest) => {
  return (dispatch: any) => {
    ItensServices.reorderItems(props).then(({ data }: any) => {
      dispatch(findExams());
      dispatch(fetchAllItems());
    });
  };
};

export const fetchAllItems = (props?: any) => {
  return (dispatch: any) => {
    ItensServices.fetchItem(props).then(({ data }: any) => {
      dispatch(getAllItem(data.results));
    });
  };
};
