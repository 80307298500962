import AlertService from "services/admin/AlertService";
import { ItemBank } from "services/admin/Interfaces/Types";
import { getAllBanks } from "../../Actions/Bank/action";
import { resetClickedElements } from "../../Actions/treeView/actions";
import BankServices from "./contents.service";

export const fetchItemBank = () => {
  return (dispatch: any) => {
    BankServices.fetch({ limit: 10000 }).then(({ data }: any) => {
      dispatch(getAllBanks(data.results));
    });
  };
};

export const fetchAllItemBank = () => {
  return (dispatch: any) => {
    BankServices.fetch({ limit: 10000 }).then(({ data }: any) => {
      dispatch(getAllBanks(data.results));
    });
  };
};

export const registerItemBank = (props: ItemBank) => {
  return (dispatch: any) => {
    BankServices.register(props)
      .then(({ data }: any) => {
        dispatch(resetClickedElements());
        dispatch(fetchItemBank());
        AlertService.showAlert({
          message: "Banco cadastrado com sucesso.",
          type: "success",
        });
      })
      .catch(() =>
        AlertService.showAlert({
          message: "Banco não cadastrado.",
          type: "erro",
        })
      );
  };
};

export const processItemBankUpdate = (props: ItemBank) => {
  return (dispatch: any) => {
    BankServices.update(props)
      .then(({ data }: any) => {
        dispatch(resetClickedElements());
        dispatch(fetchItemBank());
        AlertService.showAlert({
          message: "Banco atualizado com sucesso.",
          type: "success",
        });
      })
      .catch(() =>
        AlertService.showAlert({
          message: "Banco não atualizado.",
          type: "erro",
        })
      );
  };
};

export const processItemBankDelete = (props: ItemBank) => {
  return (dispatch: any) => {
    BankServices.delete(props)
      .then(({ data }: any) => {
        dispatch(resetClickedElements());
        dispatch(fetchItemBank());
        AlertService.showAlert({
          message: "Banco removido com sucesso.",
          type: "success",
        });
      })
      .catch(() =>
        AlertService.showAlert({
          message: "Banco não atualizado.",
          type: "erro",
        })
      );
  };
};
