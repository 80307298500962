import { PropaneSharp } from "@mui/icons-material";
import AlertService from "services/admin/AlertService";
import { Content, Exam, Task, TaskExam } from "services/admin/Interfaces/Types";
import { getExams } from "../../Actions/Exam/action";
import { getAllTasks, removeTask } from "../../Actions/Tasks/actions";
import { resetClickedElements } from "../../Actions/treeView/actions";
import { processContentUpdate, registerContent } from "../content";
import {
  addTaskInExam,
  findExamById,
  findExams,
  processExamUpdate,
} from "../Exam";
import ExamServices, { IAddTaskInExam } from "../Exam/exam.service";
import TaskServices from "./task.service";

interface IRegisterTask {
  task: Task;
  examProps?: {
    id_exam: string;
    number_order: string;
  };
}

export const registerTask = ({ task, examProps }: IRegisterTask) => {
  return (dispatch: any) => {
    TaskServices.registerTask(task)
      .then(async ({ data }: any) => {
        //dispatch(createTask());
        console.log("aqui");
        if (examProps?.id_exam) {
          const number = examProps?.number_order
            ? Number(examProps?.number_order) + 1
            : 1;

          await ExamServices.addTaskInExam({
            number,
            exam: examProps?.id_exam,
            task: data.id,
          }).then(() => dispatch(findExamById(Number(examProps?.id_exam))));
        }

        AlertService.showAlert({
          message: "Tarefa adicionada ao banco",
          type: "success",
        });
        dispatch(getAllTasks([data]));
      })
      .catch(() =>
        AlertService.showAlert({
          message: "Tarefa não adicionada ao banco",
          type: "erro",
        })
      );
  };
};

export const registerTaskWithExam = (props: Task, idExam: number) => {
  return (dispatch: any) => {
    TaskServices.registerTask(props)
      .then(({ data }: any) => {
        ExamServices.getExamById(idExam).then((response: any) => {
          let dataExam = response.data;
          const lastIndex =
            (dataExam as Exam).tasks.length > 0
              ? Math.max(...(dataExam as Exam).tasks.map((e) => e.number))
              : 0;

          const examToAdd: IAddTaskInExam = {
            exam: String(idExam),
            number: lastIndex + 1,
            task: String(data.id),
          };

          dispatch(getAllTasks([data]));
          dispatch(addTaskInExam(examToAdd));
        });
      })
      .catch(() => {
        AlertService.showAlert({
          message: "Tarefa não adicionada",
          time: 5000,
          type: "erro",
        });
      });
  };
};

export const moveTaskInsideExam = (
  props: Task,
  idExam: number,
  direction: "UP" | "DOWN"
) => {
  return (dispatch: any) => {
    ExamServices.getExamById(idExam).then((response: any) => {
      let dataExam: Exam = response.data;
      let taskExam = dataExam.tasks.find((entry) => props.id === entry.task);
      if (taskExam) {
        const factor = direction === "UP" ? -1 : +1;
        const lastIndex = Math.max(
          ...(dataExam as Exam).tasks.map((e) => e.number)
        );
        if (
          (taskExam.number === 0 && direction === "UP") ||
          (taskExam.number === lastIndex && direction === "DOWN")
        ) {
          return;
        }
        const currentTaskIndex = taskExam.number;
        const targetTaskIndex = taskExam.number + factor;

        dataExam.tasks.forEach((element, i) => {
          if (element.task !== props.id && element.number === targetTaskIndex) {
            element.number = currentTaskIndex;
          }

          if (element.task === props.id) {
            element.number = targetTaskIndex;
          }
        });
      }

      ExamServices.updateExam(dataExam).then(({ data }: any) => {
        dispatch(getExams([data]));
      });
    });
  };
};

export const getTasks = (props?: any) => {
  return (dispatch: any) => {
    TaskServices.getAllTasks(props).then(({ data }: any) => {
      const tasks: Task[] = data.results;
      for (let task of tasks) {
        task.items.sort((a, b) => {
          if (a && b && a != null && b != null)
            return (a.number as number) - (b.number as number);
          else if (a) return 1;
          else if (b) return -1;
          else return 0;
        });
      }
      dispatch(getAllTasks(tasks));
    });
  };
};

export const getTaskById = (id: number) => {
  return (dispatch: any) => {
    TaskServices.findById(id).then(({ data }: any) => {
      dispatch(getAllTasks([data]));
    });
  };
};

export const processTaskUpdate = (props: Task) => {
  return (dispatch: any) => {
    TaskServices.updateTask(props).then(({ data }: any) => {
      //dispatch(updateTask());
      dispatch(getAllTasks([data]));
    });
  };
};

export const processTaskDelete = (
  props: Task,
  successCallBack: any,
  errorCallBack: any
) => {
  return (dispatch: any) => {
    TaskServices.deleteTask(props)
      .then(({ data: any }) => {
        //dispatch(removeTask());
        dispatch(getTasks());
        dispatch(resetClickedElements());
        dispatch(removeTask(props.id as number));
        successCallBack();
      })
      .catch(() => errorCallBack());
  };
};

export const registerTaskWithContent = (task: Task, content: Content) => {
  return (dispatch: any) => {
    TaskServices.registerTask(task).then(({ data }: any) => {
      dispatch(getTasks());
      content.object = (data as Task).id as number;
      content.title = (data as Task).title as string;
      dispatch(registerContent(content));
    });
  };
};

export const updateTaskWithContent = (task: Task, content: Content) => {
  return (dispatch: any) => {
    TaskServices.updateTask(task).then(({ data }: any) => {
      dispatch(getTasks());
      content.object = (data as Task).id as number;
      content.title = (data as Task).title as string;
      dispatch(processContentUpdate(content));
    });
  };
};
