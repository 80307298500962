import { Button, Dialog, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';

export default function DialogConfirm({ isOpen, title, textInfo, onConfirm, onClose }) {

    return (

        <Dialog
            open={isOpen}
            sx={{ width: "100%" }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", width: "500px" }}>
                <Box p={1} mt={2} >
                    <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "space-between", width:"100%"}}>
                        <Typography align="left" fontWeight="600" ml={3}>
                            {title}
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={() => onClose()}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: 8,
                                color: theme.palette.grey[500],
                                mr: 1
                            })}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Box>
                <Box
                    p={1}
                    ml={3}
                    sx={{
                        alignItems: "start",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography align="left" mt={2}>

                        <span> {textInfo} </span>

                    </Typography>
                </Box>
                <Box ml={4} mb={5} mt={2}>

                    <Button onClick={(e) => onConfirm()} variant="contained" style={{ textTransform: "uppercase", marginRight: "8px" }}>
                        Confirmar
                    </Button>

                    <Button onClick={(e) => onClose()} variant="outlined" style={{ color: "black", border: "1px solid black", textTransform: "uppercase" }}>
                        Cancelar
                    </Button>

                </Box>
            </Box>
        </Dialog>
    );
}
