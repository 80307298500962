import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ItemBank,
  ItemBankFormData,
  SelectedElement,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  processItemBankUpdate,
  registerItemBank,
} from "store/reducers/Admin/AsyncActions/bank";
import { validateFormData } from "../validators/bankValidator";
import CardBase from "./CardBase";

const CardBankRegister = (props) => {
  const dispatch = useDispatch();

  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  let dataForm: ItemBankFormData = {
    ...selectedElement.elementDataForm,
  } as ItemBankFormData;

  const handleDataForm = (key: string, value: unknown) => {
    let form = { ...dataForm };
    let sel = { ...selectedElement };
    form[key] = value;

    sel.elementDataForm = form;
    dispatch(setSelectedElement(sel));
  };

  const buildBankFromForm = () => {
    let bank: ItemBank = {
      id: dataForm.id,
      name: dataForm.name,
    };
    return bank;
  };

  return (
    <CardBase
      description="Banco de Tarefas e Itens"
      title={dataForm.id ? "Banco" : "Inserir Nova Banco"}
      actionRegister={() => registerItemBank(buildBankFromForm())}
      actionUpdate={() => processItemBankUpdate(buildBankFromForm())}
      validateMethod={() => validateFormData(dataForm)}
      postSubmitRedirectUrl="/editor"
      dataForm={dataForm}
      editorContent={true}
    >
      <Box
        display="flex"
        columnGap={2}
        flexDirection={"column"}
        paddingBottom="30%"
      >
        <InputLabel
          sx={{ color: "#121517", fontWeight: "600", paddingBottom: "10px" }}
          required
        >
          Titulo
        </InputLabel>
        <FormControl fullWidth style={{ flex: 1 }}>
          <TextField
            placeholder="Digite o título do Banco"
            size="small"
            required
            value={dataForm.name}
            onChange={(e) => handleDataForm("name", e.target.value)}
            sx={{ background: "white" }}
          />
        </FormControl>
      </Box>
    </CardBase>
  );
};

export default CardBankRegister;
