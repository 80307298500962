import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Ability } from "services/admin/Interfaces/Types";
import {
  BNCCAbilityInterfaces,
  BnccAbilityService,
} from "store/reducers/bncc_abilities/bncc-ability.service";
import { useGetByAbilityId } from "store/reducers/bncc_abilities/hooks/bncc-queries";
import { z } from "zod";
import "./AddBNCCModal.scss";
import { Delete } from "@mui/icons-material";

const LIMIT = 5;

type CreateBNCC = Partial<BNCCAbilityInterfaces.Create>;

const scheme = z.object({
  code: z.string().min(1, { message: "Código obrigatório" }).max(60, {
    message: "Código deve ter no maximo 60 caracteres",
  }),
  description: z.string().min(1, { message: "Descrição obrigatória" }),
});

const getErrorMessage = (
  error: FieldErrors<CreateBNCC>,
  key: string
): string => {
  if (!error[key]) return "";
  if (!error[key]?.message) return "";

  return error[key]?.message as string;
};

interface Props {
  onCancel: () => void;
  open: boolean;
  abilities: Ability[];
}
export const AddBNCCModal = ({ onCancel, open, abilities }: Props) => {
  // STATES
  const [page, setPage] = useState(0);
  const [showForm, setShowForm] = useState(false);

  // FORM
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    resolver: zodResolver(scheme),
  });

  const {
    data: bnccData,
    isLoading,
    refetch,
    isError,
  } = useGetByAbilityId(abilities[0]?.id || 0);

  const onSubmit = (data: CreateBNCC) => {
    if (!abilities.length) return;

    data.abilities = abilities.map((item) => item.id!) || [];

    const createBNCCPromise = BnccAbilityService.create(
      data as BNCCAbilityInterfaces.Create
    );

    toast.promise(createBNCCPromise, {
      error: "Erro ao adicionar BNCC",
      success: "BNCC adicionado com sucesso",
      pending: "Adicionando BNCC",
    });

    createBNCCPromise.then(() => {
      reset();
      refetch();
      setShowForm(false);
      setPage(0);
    });
  };

  const onClose = () => {
    setShowForm(false);
    setPage(0);
    reset();
    onCancel();
  };
  console.log({ bnccData });
  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Adicionar BNCC</DialogTitle>
      <DialogContent>
        <DataGrid
          loading={isLoading}
          error={isError && "Erro ao carregar dados"}
          autoHeight
          columns={[
            { field: "code", headerName: "Código" },
            { field: "description", headerName: "Descrição" },
            // {
            //   field: "delete",
            //   headerName: "Excluir",
            //   sortable: false,
            //   renderCell: (param) => {
            //     return (
            //       <Button
            //         onClick={() => {
            //           // BnccAbilityService.delete(param.row.id!);
            //           console.log(param);
            //           refetch();
            //         }}
            //       >
            //         <Delete htmlColor="#aa0000" />
            //       </Button>
            //     );
            //   },
            // },
          ]}
          rows={bnccData || []}
          pagination={undefined}
          isRowSelectable={() => false}
          hideFooter
          disableColumnMenu
          sortModel={[]}
        />

        <form
          hidden={!showForm}
          className="add-bncc-form"
          onSubmit={handleSubmit(onSubmit)}
          id="add-bncc-ability-form"
        >
          <TextField
            id="code"
            label="Código"
            {...register("code")}
            error={!!errors.code}
            helperText={getErrorMessage(errors, "code")}
          />
          <TextField
            id="description"
            label="Descrição"
            {...register("description")}
            error={!!errors.description}
            helperText={getErrorMessage(errors, "description")}
          />
        </form>

        {isError && <>Erro ao carregar dados</>}
      </DialogContent>
      <DialogActions sx={{ paddingRight: 4 }}>
        <Button
          form="add-bncc-ability-form"
          hidden={!showForm}
          variant="contained"
          type="submit"
        >
          Adicionar
        </Button>

        <Button
          hidden={showForm}
          variant="contained"
          href="#add-bncc-ability-form"
          onClick={() => setShowForm(true)}
        >
          Adicionar BNCC
        </Button>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};
