import { createRoot } from 'react-dom/client';

import App from "./App";
import "./index.scss";

import { ssoConfig } from "./ssoConfig";
import { setAuthConfig } from "@cenpe/auth";

setAuthConfig(ssoConfig);

const root = createRoot(document.getElementById("root")!);
root.render(<App />)
