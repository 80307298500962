import { useQuery } from "react-query";
import {
  BNCCAbilityInterfaces,
  BnccAbilityService,
  RequestPagination,
} from "../bncc-ability.service";
import { Page } from "services/admin/Interfaces/Types";

// Listar bncc abilities com react query

export enum BnccQueryKeys {
  listAll = "listAll",
  listByAbilityId = "listByAbilityId",
}

// Services
const loadBnccAbility = async ({
  queryKey,
}): Promise<Page<BNCCAbilityInterfaces.List>> => {
  const limit = queryKey[1] || 0;
  const page = queryKey[2] || 0;
  return (await BnccAbilityService.loadAll({ page, limit })).data;
};

const loadByAbilityId = async ({ queryKey }) => {
  const abilityId = queryKey[1];
  return await BnccAbilityService.getByAbilityId(abilityId);
};

// Hooks
export const useListBnccAbility = (requestData: RequestPagination = {}) => {
  return useQuery({
    queryKey: [BnccQueryKeys.listAll, requestData.limit, requestData.page],
    queryFn: loadBnccAbility,
  });
};

export const useGetByAbilityId = (abilityId: number) => {
  return useQuery({
    queryKey: [BnccQueryKeys.listByAbilityId, abilityId],
    queryFn: loadByAbilityId,
  });
};
