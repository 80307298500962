import { Button, Divider } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ContentFormData,
  ElementTypes,
  ExamFormData,
  SelectedElement,
  TaskFilters,
} from "services/admin/Interfaces/Types";
import {
  findExamById,
  findExams,
} from "store/reducers/Admin/AsyncActions/Exam";
import ExamServices from "store/reducers/Admin/AsyncActions/Exam/exam.service";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import ContentServices from "store/reducers/Admin/AsyncActions/content/contents.service";
import { Header } from "./header";
import "./index.scss";
import { SearchArea } from "./search-area";
import { SelectTask } from "./select-task";
import { Filters, Option } from "./types";
import { useSelects } from "./use-selects";
import { Ids } from "./constants";
import { getTaskById } from "store/reducers/Admin/AsyncActions/Tasks";

export function CardLoadTask() {
  const {
    ability,
    knowledge,
    knowledgeArea,
    examSelect,
    loadKnowledge,
    loadAbilities,
    loadKnowledgeArea,
  } = useSelects();

  const navigate = useHistory();
  const dispatch = useDispatch();

  const [filters, setFilter] = React.useState<Filters>({});
  const [taskList, setTaskList] = React.useState<Option[]>([]);
  const [loadingTasks, setLoadingTasks] = React.useState(false);

  const [selectedTasks, setSelectedTasks] = React.useState<number[]>([]);

  const selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  function loadTasks() {
    setLoadingTasks(true);
    setTaskList([]);
    TaskServices.getAllTasks({ ...makePayload(), limit: 10000 }).then(
      ({ data }: any) => {
        const response: any[] = data.results;
        const tasks = response.map<Option>((task) => ({
          id: task.id!,
          name: task.title,
        }));
        setTaskList(tasks);
        setLoadingTasks(false);
      }
    );
  }

  function makePayload(): TaskFilters {
    const payload: TaskFilters = {};
    const {
      [Ids.ABILITY]: ability,
      [Ids.KNOWLEDGE]: knowledge,
      [Ids.KNOWLEDGE_AREA]: knowledgeArea,
      // [Ids.EXAM]: exam,
    } = filters;

    if (ability) payload.ability = ability;
    if (knowledge) payload.knowledge = knowledge;
    if (knowledgeArea) payload.knowledge_area = knowledgeArea;
    // if (examSelect) payload.exam = exam;
    return payload;
  }

  function handleClickSent() {
    const { elementType } = selectedElement;

    if (elementType === ElementTypes.EXAM) {
      createExamTask();
      dispatch(findExams());
    }

    if (elementType === ElementTypes.CONTENT) {
      selectedTasks.forEach(async (id) => {
        await createContentTask(id);
      });
    }
    goToStart();
  }

  function goToStart() {
    navigate.push("/editor");
  }

  async function addTask(idTask: number, order: number) {
    await ExamServices.addTaskInExam({
      number: order,
      exam: String((selectedElement.elementDataForm as ExamFormData).id),
      task: String(idTask),
    }).then(({ data }: any) => {
      dispatch(findExamById(data.exam));
      dispatch(getTaskById(data.task));
    });
  }

  function maxOrder(orders: number[]) {
    if (orders.length === 0) return -1;
    return Math.max(...orders);
  }

  function createExamTask() {
    const { elementDataForm } = selectedElement;
    const orders = (elementDataForm as ExamFormData).tasks.map(
      (task) => task.number
    );
    let nextOrder = maxOrder(orders) + 1;

    selectedTasks.forEach(async (id) => {
      await addTask(id, nextOrder);
      nextOrder++;
    });
  }

  async function createContentTask(id: number) {
    const { elementDataForm } = selectedElement;
    await ContentServices.register({
      parent: (elementDataForm as ContentFormData).id,
      subcontent_type: "task",
      object: id,
    });
  }

  function loadData(value: string, name: string) {
    if (name === Ids.KNOWLEDGE_AREA) {
      loadKnowledge(value);
    }

    if (name === Ids.KNOWLEDGE) {
      loadAbilities(value);
    }
  }

  const actions = {
    onChange(value: string, name: string) {
      setFilter((currentState) => ({ ...currentState, [name]: value }));
      loadData(value, name);
    },
    onFilter() {
      loadTasks();
    },
    onReset() {
      setFilter({});
      loadKnowledgeArea();
      loadTasks();
    },
  };

  const selects = [examSelect, knowledgeArea, knowledge, ability];
  return (
    <section className="card-load-task">
      <Header headerText="Carregar Tarefa" />
      <SearchArea
        filters={filters}
        loading={loadingTasks}
        selects={selects}
        {...actions}
      />
      <Divider sx={{ mt: "1rem", ml: "2rem", mr: "2rem", mb: "1rem" }} />
      {!!taskList.length && (
        <>
          <SelectTask
            tasks={taskList}
            onChange={(selecteds) => {
              setSelectedTasks(selecteds);
            }}
          />

          <Divider sx={{ mt: "1rem", ml: "2rem", mr: "2rem", mb: "1rem" }} />

          <div className="actions">
            <Button
              onClick={handleClickSent}
              disabled={selectedTasks.length === 0}
              variant="contained"
            >
              Carregar
            </Button>
            <Button variant="text" onClick={goToStart}>
              Cancelar
            </Button>
          </div>
        </>
      )}
    </section>
  );
}
