import { useState } from "react";

import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Box,
  InputLabel,
} from "@mui/material";

import SortIcon from "@mui/icons-material/Sort";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import "./styles.css";

function not(a: any[], b: any[]): any[] {
  return a.filter((value) => b?.indexOf(value) === -1);
}

function intersection(a: any[], b: any[]): any[] {
  return a.filter((value) => b?.indexOf(value) !== -1);
}

interface ITransferList {
  skills: any[];
  setSkills: any;
  selectedSkills: any[];
  deleteItemAbility: any;
  setItemAbilities: any;
  filterButtonClear: any;
  filterButton: any;
  setSelectedSkills: any;
}

type Skill = {
  ability_id: number;
  code: string;
  description: string;
  id: number;
  knowledge: number;
  name: string;
};

export default function TransferList({
  skills = [],
  setSkills,
  setItemAbilities,
  deleteItemAbility,
  selectedSkills = [],
  filterButtonClear,
  filterButton,
  setSelectedSkills,
}: ITransferList) {
  const [checked, setChecked] = useState<any[]>([]);
  const leftChecked = intersection(checked, skills);
  const rightChecked = intersection(checked, selectedSkills);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = async () => {
    for (const skill of leftChecked) {
      setItemAbilities(skill.id);
    }
    setSelectedSkills(selectedSkills.concat(leftChecked));
    setSkills(not(skills, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = async () => {
    setSkills(skills ? skills.concat(rightChecked) : rightChecked);
    setSelectedSkills(not(selectedSkills, rightChecked));
    setChecked(not(checked, rightChecked));

    for (const skill of rightChecked) {
      await deleteItemAbility(skill.ability_id);
    }
  };

  const customList = (title: string, items: any) => (
    <Box
      style={{
        border: "1px solid #969DA9",
        width: "100%",
        height: "220px",
        borderRadius: "4px",
        justifyContent: "space-between",
        overflow: "auto",
      }}
    >
      <List
        sx={{
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items?.map((skill: any) => {
          const labelId = `transfer-list-all-item-${skill}-label`;
          return (
            <ListItemButton
              key={skill}
              role="listitem"
              onClick={handleToggle(skill)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked && checked.indexOf(skill) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${skill?.name}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );

  return (
    <>
      <InputLabel className="title-form-item">Habilidade(s) do Item</InputLabel>
      <Box>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item sm={12} md={5.5}>
            {customList("Available Skills", skills)}
          </Grid>
          <Grid item sm={12} md={1}>
            <Grid container direction="column" alignItems="center">
              <Grid sm={12} md={12}>
                <Button
                  style={{ width: "20px", height: "41px" }}
                  variant="contained"
                  size="large"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  <KeyboardArrowRightIcon style={{ fontSize: 32 }} />
                </Button>
              </Grid>
              <Grid sm={12} md={12}>
                <Button
                  style={{ width: "20px", height: "41px" }}
                  sx={{ my: 0.5 }}
                  variant="contained"
                  size="large"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  <KeyboardArrowLeftIcon style={{ fontSize: 32 }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={5.5}>
            {customList("Selected Skills", selectedSkills)}
          </Grid>
        </Grid>
      </Box>

      <div className="button-filter">
        <div>{selectedSkills?.length} Habilidade(s) em uso</div>
        <div>
          <Button
            onClick={filterButton}
            sx={{ my: 0.5 }}
            variant="contained"
            color="primary"
            endIcon={<SortIcon />}
          >
            Filtrar Habilidades
          </Button>
          <Button
            onClick={filterButtonClear}
            sx={{ my: 0.5 }}
            variant="contained"
            endIcon={<FilterListOffIcon />}
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: "4px",
              border: "1px solid #CACDD5",
              marginLeft: "10px",
            }}
          >
            Limpar Filtro
          </Button>
        </div>
      </div>
    </>
  );
}

// return (
//     <>
//         <InputLabel className="title-form-item">Habilidade(s) do Item</InputLabel>
//         <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: "10px" }}>
//             <Grid item>{customList('Choices', left)}</Grid>
//             <Grid item>
//                 <Grid container direction="column" alignItems="center">
//                     <Button
//                         sx={{ my: 0.5 }}
//                         variant="contained"
//                         color='primary'
//                         onClick={handleCheckedRight}
//                         disabled={leftChecked.length === 0}
//                         aria-label="move selected right"
//                     >
//                         &gt;
//                     </Button>
//                     <Button
//                         sx={{ my: 0.5 }}
//                         variant="contained"
//                         color='primary'
//                         onClick={handleCheckedLeft}
//                         disabled={rightChecked.length === 0}
//                         aria-label="move selected left"
//                     >
//                         &lt;
//                     </Button>
//                 </Grid>
//             </Grid>
//             <Grid item>{customList('Chosen', right)}</Grid>
//         </Grid>

//     </>
// );
