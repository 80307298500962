import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { Box, width } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Knowledge,
  KnowledgeArea,
  Page,
} from "services/admin/Interfaces/Types";
import { getKnowledgesArea } from "store/reducers/Admin/Actions/KnowledgeArea/action";
import { findKnowledges } from "store/reducers/Admin/AsyncActions/KnowledgesArea";
import CardBase from "./CardBase";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  fetchAllKnowledge,
  fetchKnowledge,
  removeKnowledge,
} from "store/reducers/knowledges/AsyncActions";
import { SET_DATA_FORM_KNOWLEDGE } from "store/reducers/knowledges/Actions";
import { validateFormData } from "../validators/knowledgeValidator";
import { DialogDelete, IDialogDelete } from "components/DialogDelete";
import KnowledgeService from "store/reducers/knowledges/AsyncActions/knowledge.service";
import { toast } from "react-toastify";
import { ptBR } from "@mui/x-data-grid/locales";
import { Label } from "components/Label";

const emptyKnowledge: Knowledge = {
  name: "",
  code: "",
  knowledge_area: 0,
};

function buildTableReader(deleteFunction, editFunction): GridColDef[] {
  const defColumn: GridColDef[] = [
    { field: "id", headerName: "Id", width: 90 },
    { field: "code", headerName: "Code", width: 100 },
    { field: "name", headerName: "Nome", width: 500 },
    {
      field: "knowledgeAreaName",
      headerName: "Área do Conhecimento",
      width: 200,
    },
    {
      field: "edit",
      headerName: "Editar",
      width: 150,
      renderCell: (param) => {
        return (
          <Button
            variant="outlined"
            sx={{
              background: "white",
              color: "#1D2432",
              borderColor: "#CACDD5",
            }}
            onClick={() => {
              editFunction(param);
            }}
          >
            <EditIcon sx={{ marginRight: "10px" }} />
            <Typography>Editar</Typography>
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Excluir",
      width: 100,
      renderCell: (param) => {
        return (
          <Button
            variant="outlined"
            sx={{
              background: "white",
              color: "#1D2432",
              padding: 1,
              borderColor: "#CACDD5",
            }}
            onClick={() => {
              deleteFunction(param);
            }}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  return defColumn;
}

const CardRegisterKnowledge = (props) => {
  const dispatch = useDispatch();
  const [allKnowledge, setAllKnowledge] = useState([] as Knowledge[]);
  const dataForm = useSelector((state: any) => state.knowledges).dataForm;
  const [currentPage, setCurrentPage] = useState(0);

  const knowledgeAreas: Page<KnowledgeArea> = useSelector(
    (state: any) => state.admin
  ).knowledgeAreas;

  const knowledges: Page<Knowledge> = useSelector(
    (state: any) => state.knowledges
  ).currentPage;

  const [openDialogDeleteKnowledge, setOpenDialogDeleteKnowledge] =
    useState<IDialogDelete>({
      isOpen: false,
      onClose: () => {},
      onConfirm: () => {},
    });

  useEffect(() => {
    dispatch(fetchKnowledge(5, 0, undefined));
    dispatch(findKnowledges());
    fetchAllKnowledge().then((response: any) => {
      setAllKnowledge(response.data.results);
    });
  }, [dispatch]);

  const handleDataForm = (field, value) => {
    const newData: Knowledge = { ...dataForm };
    newData[field] = value;
    dispatch(SET_DATA_FORM_KNOWLEDGE(newData));
  };

  const changePage = (e) => {
    setCurrentPage(e);
    dispatch(fetchKnowledge(5, 5 * e, undefined));
  };

  const postSubmitAction = () => {
    dispatch(SET_DATA_FORM_KNOWLEDGE(emptyKnowledge));
  };

  const buildPageData = (): Knowledge[] => {
    let knowledgeNew: Knowledge[] = [];
    if (knowledgeAreas.results && allKnowledge) {
      knowledges.results.forEach((kn) => {
        let newKn = { ...kn };

        newKn.knowledgeAreaName = knowledgeAreas.results.find(
          (kn) => kn.id === newKn.knowledge_area
        )?.name;

        knowledgeNew.push(newKn);
      });
    }

    return knowledgeNew;
  };

  const onError = (error: any) => {
    if (
      error.response.data.code[0] === "knowledge with this Code already exists."
    ) {
      toast.error("O código inserido já existe em nossa base de dados.");
    }
  };

  const onSuccess = (update = false) => {
    postSubmitAction();
    toast.success(update ? "Modificado" : "Cadastrado.");
  };

  const createKnowledge = (
    knowledge: Knowledge,
    currentPage: number | undefined
  ) => {
    return (dispatch: any) => {
      KnowledgeService.createKnowledge(knowledge)
        .then(() => {
          let offset = 0;
          if (currentPage) offset = 5 * currentPage;
          dispatch(fetchKnowledge(5, offset, undefined));
          onSuccess();
        })
        .catch(onError);
    };
  };

  const updateKnowledge = (
    knowledge: Knowledge,
    currentPage: number | undefined
  ) => {
    return (dispatch: any) => {
      KnowledgeService.updateKnowledge(knowledge)
        .then(() => {
          let offset = 0;
          if (currentPage) offset = 5 * currentPage;
          dispatch(fetchKnowledge(5, offset, undefined));
          const update = true;
          onSuccess(update);
        })
        .catch(onError);
    };
  };

  const genericProcessing = () => {
    validateFormData(dataForm);
    if (dataForm.id) {
      dispatch(updateKnowledge(dataForm, currentPage));
    } else {
      dispatch(createKnowledge(dataForm, currentPage));
    }
  };

  let pageData: Knowledge[] = buildPageData();

  const generateSelectKnowledges = (label?: string) => (
    <Select
      value={dataForm.knowledge_area}
      label={label ?? "Área do Conhecimento"}
      sx={{ background: "white" }}
      onChange={(e) => handleDataForm("knowledge_area", e.target.value)}
      defaultValue={0}
    >
      <MenuItem key={"list_knowledges_0"} value={0}>
        {"Área do Conhecimento"}
      </MenuItem>
      {knowledgeAreas.results != undefined
        ? knowledgeAreas.results.map((i, key) => (
            <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
              {i.name}
            </MenuItem>
          ))
        : null}
    </Select>
  );

  return (
    <CardBase
      title={"Gerenciamento de Conhecimentos"}
      showActionButtons={false}
      dataForm={dataForm}
      validateMethod={validateFormData}
    >
      <Grid container spacing={1} paddingTop={1}>
        <Grid item xs={3} lg={2}>
          <FormControl sx={{ width: "100%" }}>
            <Label htmlFor="code" text="Código" required />
            <TextField
              id="code"
              value={dataForm.code}
              onChange={(e) => handleDataForm("code", e.target.value)}
              sx={{ background: "white" }}
              inputProps={{ maxLength: 10 }}
              required
              placeholder="Código"
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl sx={{ width: "100%" }}>
            <Label htmlFor="name" text="Nome" required />
            <TextField
              id="name"
              value={dataForm.name}
              onChange={(e) => handleDataForm("name", e.target.value)}
              sx={{ background: "white" }}
              required
              placeholder="Nome"
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} lg={3}>
          <FormControl fullWidth>
            <Label
              htmlFor="knowledge_area"
              text="Área do Conhecimento"
              required
            />
            {generateSelectKnowledges('')}
          </FormControl>
        </Grid>

        <Grid item xs={4} md={2} lg={2}>
          <Button
            sx={{
              p: 1,
              mt: 2.5,
              width: "100%",
            }}
            onClick={(e) => genericProcessing()}
            variant="contained"
            disabled={
              !(dataForm.name && dataForm.code && dataForm.knowledge_area != 0)
            }
          >
            <Typography>Cadastrar</Typography>
          </Button>
        </Grid>

        <Grid item xs={4} md={2} lg={2}>
          <FormControl fullWidth>
            <Button
              sx={{
                p: 1,
                width: "100%",
                background: "#EDF1FF",
                color: "#365BDC",
                mt: 2.5,
              }}
              onClick={() => {
                dispatch(SET_DATA_FORM_KNOWLEDGE(emptyKnowledge));
              }}
              variant="contained"
            >
              <Typography>Cancelar</Typography>
            </Button>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            columns={buildTableReader(
              (dataTable: Knowledge) => {
                setOpenDialogDeleteKnowledge({
                  isOpen: true,
                  onClose: () => {
                    setOpenDialogDeleteKnowledge({
                      isOpen: false,
                      onClose: () => {},
                      onConfirm: () => {},
                    });
                  },
                  onConfirm: () => {
                    dispatch(removeKnowledge(dataTable));
                    setOpenDialogDeleteKnowledge({
                      isOpen: false,
                      onClose: () => {},
                      onConfirm: () => {},
                    });
                  },
                });
              },
              (dataTable) => {
                dispatch(SET_DATA_FORM_KNOWLEDGE(dataTable.row));
              }
            )}
            rowCount={knowledges.count}
            rows={pageData}
            pageSize={5}
            rowsPerPageOptions={[5]}
            onPageChange={(e) => changePage(e)}
            pagination
            paginationMode="server"
          />
        </Grid>
      </Grid>
      <DialogDelete {...openDialogDeleteKnowledge} />
    </CardBase>
  );
};

export default CardRegisterKnowledge;
