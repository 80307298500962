import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Ability,
  Serie,
  SimpleAbilitySerie,
} from "services/admin/Interfaces/Types";
import { LIMIT } from "store/reducers/Admin/AsyncActions/KnowledgesArea/knowledges-area.services";
import {
  deleteSerieAbilityWithCallback,
  fetchSerie,
  registerSerieAbilityWithCallBack,
} from "store/reducers/Serie_Ability/AsyncActions";

interface SerieAbilityDataSource {
  id: number;
  ability: string;
  serie: string;
}

const columns = (deleteFunction): GridColDef[] => {
  return [
    {
      field: "serie",
      headerName: "Série",
      width: 1000,
      sortable: false,
    },
    {
      field: "delete",
      headerName: "Excluir",
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            sx={{ color: "black" }}
            onClick={() => {
              deleteFunction(param);
            }}
          >
            <Delete />
          </Button>
        );
      },
    },
  ];
};

export interface IModalAbilitySerie {
  opened: boolean;
  ability: Ability;
  closeAction: any;
}

const ModalAbilitySerie = (props: IModalAbilitySerie) => {
  const data = useSelector(
    (state: any) => state.abilitySeries
  ).modalAbilitieSeries;

  const { opened, ability, closeAction } = props;
  const [persistData, setPersistData] = useState(0);
  const [showAddPanel, setShowAddPanel] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSerie({ limit: 1000, offset: 0 }));
  }, [dispatch, ability, ability.series]);

  const dataSerie: Serie[] = useSelector(
    (state: any) => state.abilitySeries
  ).dataSerie;

  const buildDataSource = (
    dados: SimpleAbilitySerie[]
  ): SerieAbilityDataSource[] => {
    let value: SerieAbilityDataSource[] = [];
    if (dados) {
      dados.forEach((p) => {
        const entry: SerieAbilityDataSource = {
          id: p.id as number,
          serie: p.serie_name,
          ability: ability.name,
        };

        value.push(entry);
      });
    }

    return value;
  };

  const register = () => {
    if (persistData > 0) {
      dispatch(
        registerSerieAbilityWithCallBack(
          {
            serie: persistData,
            ability: ability.id as number,
          },
          () => {
            //refresh();
          }
        )
      );
      setPersistData(0);
      setShowAddPanel(false);
    }
  };

  const excluirDados = (id: number) => {
    dispatch(
      deleteSerieAbilityWithCallback(id, ability.id as number, () => {
        //refresh();
      })
    );
  };

  const getSeriesAvailableToAdd = (): Serie[] => {
    let series: Serie[] = [];
    dataSerie.forEach((s) => {
      if (!data?.find((r) => r.serie_id === s.id)) {
        series.push(s);
      }
    });

    return series;
  };

  return (
    <Dialog sx={{ width: "100%" }} fullWidth maxWidth={"lg"} open={opened}>
      <Box p={2} sx={{ width: "100%", background: "#EAF2F7" }}>
        <Box p={1} sx={{ background: "#F3F7F9" }}>
          <Typography variant="h4" align="center" fontWeight="600">
            Series Vinculadas à Habilidade "{ability.name}"
          </Typography>
        </Box>

        {showAddPanel ? (
          <Box p={2}>
            <FormControl sx={{ m: "1", background: "white" }} fullWidth>
              <InputLabel id="select-serie">Series</InputLabel>
              <Select
                value={persistData}
                label="serie"
                defaultValue={0}
                onChange={(e) => setPersistData(e.target.value as number)}
              >
                <MenuItem value={0}>Selecione a serie</MenuItem>
                {getSeriesAvailableToAdd().map((s) => (
                  <MenuItem value={s.id}>{s.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              p={1}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <Button
                sx={{ marginRight: "0", marginLeft: "auto" }}
                variant="contained"
                onClick={(e) => register()}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        ) : null}

        <Box p={2}>
          <DataGrid
            sx={{ width: "100%", margin: "auto", background: "white" }}
            autoHeight
            columns={columns((param) => {
              excluirDados(param.row.id);
            })}
            rows={buildDataSource(data)}
            rowCount={buildDataSource(data).length}
            pageSize={LIMIT}
            rowsPerPageOptions={[LIMIT]}
            disableColumnMenu
            paginationMode="client"
            getRowId={(row: SerieAbilityDataSource) =>
              "ab" + row.ability + "sr" + row.serie
            }
          ></DataGrid>
        </Box>
        <Box
          p={3}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Box sx={{ width: "10%" }}>
            <Button variant="contained" onClick={(e) => closeAction()}>
              FECHAR
            </Button>
          </Box>

          {!showAddPanel ? (
            <Box sx={{ width: "20%" }}>
              <Button
                variant="contained"
                onClick={(e) => setShowAddPanel(true)}
              >
                Adicionar Nova Serie
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModalAbilitySerie;
