import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Roboto",
  },
  components: {
    MuiTab: {
      styleOverrides: {
        textColorPrimary: "red",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#E5193B",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          paddind: 1,
          boxShadow: "none",
          transition: "background-color 0.2s",
          fontFamily: "Poppins",
          fontWeight: 600,
          ":hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          fontFamily: "Poppins",
          color: "var(--neutral-1)",
          backgroundColor: "var(--primary-regular)",
          transition: "all 0.2s linear",
          "&:hover": {
            backgroundColor: "var(--primary-dark)",
            color: "var(--neutral-1)",
          },
        },
        containedSecondary: {
          fontFamily: "Poppins",
          color: "#1D2432",
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#0001",
          },
          border: '1px solid #CACDD5',
          fontWeight: '900'
        },
        containedWarning: {
          fontFamily: "Poppins",
          color: "var(--neutral-1)",
          backgroundColor: "var(--wheat-regular)",
          transition: "all 0.2s linear",
          "&:hover": {
            backgroundColor: "var(--wheat-regular)",
            filter: "brightness(0.9)",
            color: "var(--neutral-1)",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 6,
          transition: 'all 0.2s linear',

          '&:hover': {
            '& .MuiSwitch-track': {
              backgroundColor: 'var(--primary-light) !important'
            },
          },

          '& .MuiSwitch-switchBase': {
            transitionDuration: '300ms',

            '&.Mui-checked': {
              '& .MuiSwitch-thumb': {
                backgroundColor: 'var(--primary-regular)',
              },

              '& + .MuiSwitch-track': {
                opacity: 1,
                border: '2px solid var(--primary-dark)',
                backgroundColor: 'white',
              },

              '&.Mui-disabled': {
                '&:hover': {
                  '& + .MuiSwitch-track': {
                    backgroundColor: 'var(--neutral-3) !important'
                  },
                },
              },

              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: 'var(--neutral-3)'
              },
            },

            '&.Mui-disabled': {
              pointerEvents: 'all',
              cursor: 'not-allowed !important',

              '&:hover + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'var(--neutral-3) !important'
              },

              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'var(--neutral-3)'
              },
            },
          },

          '& .MuiSwitch-track': {
            borderRadius: 12,
            border: '2px solid var(--neutral-5)',
            backgroundColor: 'white',
            opacity: 1,
            transition: 'all 0.2s linear',
            height: 24,
            width: 45,
          },

          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 14,
            height: 14,
            margin: 2,
            backgroundColor: 'var(--neutral-5)',
            transition: 'all 0.2s linear',
          },
        },
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'var(--neutral-5)',

          '&.Mui-checked': {
            color: 'var(--primary-regular)'
          }
        }
      }
    },
  },
});
