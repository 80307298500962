import { Tooltip } from "@mui/material";
import { SquareButton } from "components/SquareButton";
import { SquareSvg } from "components/SquareButton/square-svg";
import { useHistory } from "react-router-dom";

interface Props {
  svgImage: any;
  active: boolean;
  action?: any;
  redirectPath?: string;
  toolTipText: string;
}

export default function EditNavHeadButton({
  svgImage,
  active,
  action,
  redirectPath,
  toolTipText,
}: Props) {
  const history = useHistory();
  return (
    <Tooltip title={toolTipText}>
      <SquareButton
        active={active}
        onClick={() => {
          if (!active) return;

          if (redirectPath) history.push(redirectPath as string);
          if (action) action();
        }}
      >
        <SquareSvg>{svgImage}</SquareSvg>
      </SquareButton>
    </Tooltip>
  );
}
