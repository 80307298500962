import { FormControl, InputLabel, Switch, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  Item,
  ItemBank,
  SelectedElement,
  /*   EditorModalState, */
  Task,
  TaskFormData,
} from "services/admin/Interfaces/Types";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import {
  processTaskUpdate,
  registerTask,
  registerTaskWithExam,
} from "store/reducers/Admin/AsyncActions/Tasks";

//import { openMarkdown } from "../utils/newMarkdownUtils";

import { validateFormData } from "../validators/taskValidator";
import CardBase from "./CardBase";
import { InputMarkdown } from "components/InputMarkdown";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";

export default function CardTaskRegister() {
  const { bankId } = useParams() as any;

  const dispatch = useDispatch();

  let selectedElement: SelectedElement = {
    ...useSelector((state: any) => state.admin).selectedElement,
  };

  let initialDataForm = {...selectedElement.elementDataFormInitial} as SelectedElement
  const [keysChanged, setKeysChanged] = useState<string[]>([])

  /*   let editorState: EditorModalState = {
    ...useSelector((state: any) => state.admin).editorState
  } */

  let dataForm: TaskFormData = {
    ...selectedElement.elementDataForm,
  } as TaskFormData;

  function identifyChangeOnValue(key: string, value: unknown) {
    if (!!initialDataForm[key] && String(initialDataForm[key]).trim() !== String(value).trim()) {
      setKeysChanged(prevState => [...prevState, key])
      return
    }
    
    if (!initialDataForm[key] && !!String(value).trim()) {
      setKeysChanged(prevState => [...prevState, key])
      return
    }

    setKeysChanged(prevState => (prevState.filter(prev => prev !== key)))
  }

  const handleDataForm = (key: string, value: unknown) => {
    let form = { ...dataForm };
    let sel = { ...selectedElement };
    form[key] = value;

    sel.elementDataForm = form;
    dispatch(setSelectedElement(sel));

    identifyChangeOnValue(key, value)
  };

  const buildTaskFromForm = () => {
    let task: Task = {
      id: dataForm.id,
      content: dataForm.description,
      title: dataForm.title,
      items: dataForm.items as Item[],
      is_public: dataForm.is_public,
      item_bank: bankId ? bankId : undefined,
    };

    return task;
  };

  return (
    <CardBase
      title={dataForm.id ? "Tarefa" : "Inserir Nova Tarefa"}
      actionRegister={
        selectedElement.parentElement
          ? () =>
              registerTaskWithExam(
                buildTaskFromForm(),
                selectedElement.parentElement as number
              )
          : () => registerTask({ task: buildTaskFromForm() })
      }
      actionUpdate={() => processTaskUpdate(buildTaskFromForm())}
      validateMethod={() => validateFormData(dataForm)}
      postSubmitRedirectUrl="/editor"
      disabledActionButtonsUpdate={keysChanged.length <= 0}
      dataForm={dataForm}
      editorContent={true}
    >
      <Box display="flex" columnGap={2} alignItems="flex-end">
        <Box style={{ flex: 1 }}>
          <InputLabel className="title-form-item" required>
            Título
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              placeholder="Digite o título da tarefa"
              size="medium"
              required
              value={dataForm.title}
              onChange={(e) => handleDataForm("title", e.target.value)}
              sx={{ background: "white" }}
            />
          </FormControl>
        </Box>
        <Box alignSelf="start">
          <InputLabel className="title-form-item">Público</InputLabel>
          <FormControl>
            <Switch
              value={dataForm.is_public}
              defaultChecked={dataForm.is_public ?? false}
              onChange={(e) => handleDataForm("is_public", e.target.checked)}
            />
          </FormControl>
        </Box>
      </Box>

      <Box>
        <InputMarkdown
          required
          label="Descrição da tarefa"
          textValue={dataForm.description}
          callBackValue={(e) => handleDataForm("description", e)}
        />
      </Box>
    </CardBase>
  );
}
