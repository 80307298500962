import { Button, Dialog, IconButton, Typography } from "@mui/material";
import { Box, color } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import AlertService from "services/admin/AlertService";
import {
  SelectedElement,
  Item,
  Task,
  Exam,
  Assessment,
  ElementTypes,
  TaskSubContent,
  Content,
  ItemBank,
} from "services/admin/Interfaces/Types";
import CloseIcon from '@mui/icons-material/Close';
import { processAssessmentDelete } from "store/reducers/Admin/AsyncActions/assessment";
import { processItemBankDelete } from "store/reducers/Admin/AsyncActions/bank";
import { processContentDelete } from "store/reducers/Admin/AsyncActions/content";
import { processExamDelete } from "store/reducers/Admin/AsyncActions/Exam";
import { processItemDelete } from "store/reducers/Admin/AsyncActions/Itens";
import { processTaskDelete } from "store/reducers/Admin/AsyncActions/Tasks";
import { removeSubContent } from "store/reducers/task_subcontent/AsyncActions";
import { validateDelete } from "../validators/taskValidator";

export default function DeleteDialog({ open, closeModalAction }) {
  const dispatch = useDispatch();

  const selectedElement: SelectedElement = useSelector(
    (state: any) => state.admin
  ).selectedElement;

  const exams: Exam[] = useSelector((state: any) => state.admin).exams;

  const callBackError = (componente: string) => {
    AlertService.showAlert({
      message: `Erro ao excluir ${componente}.`,
      type: "erro",
      time: 5000,
    });
  };

  const callBackSuccess = (componente: string) => {
    AlertService.showAlert({
      message: `${componente} excluido com sucesso.`,
      type: "success",
      time: 5000,
    });
  };

  const processDelection = () => {
    if (selectedElement.elementType === ElementTypes.TASK)
      dispatch(
        processTaskDelete(
          selectedElement.element as Task,
          () => callBackSuccess("Task"),
          () => callBackError("task")
        )
      );
    if (selectedElement.elementType === ElementTypes.ITEM)
      dispatch(
        processItemDelete(
          selectedElement.element as Item,
          () => callBackSuccess("Item"),
          () => callBackError("item")
        )
      );
    if (selectedElement.elementType === ElementTypes.EXAM)
      dispatch(
        processExamDelete(
          selectedElement.element as Exam,
          () => callBackSuccess("Exame"),
          () => callBackError("exame")
        )
      );
    if (selectedElement.elementType === ElementTypes.ASSESSMENT)
      dispatch(
        processAssessmentDelete(
          selectedElement.element as Assessment,
          () => callBackSuccess("Avaliação"),
          () => callBackError("avaliação")
        )
      );
    if (selectedElement.elementType === ElementTypes.TASK_SUBCONTENT)
      dispatch(
        removeSubContent(
          selectedElement.element as TaskSubContent,
          () => callBackSuccess("Sub Conteúdo"),
          () => callBackError("sub conteúdo")
        )
      );
    if (selectedElement.elementType === ElementTypes.CONTENT)
      dispatch(
        processContentDelete(
          selectedElement.element as Content,
          () => callBackSuccess("Conteúdo"),
          () => callBackError("conteúdo")
        )
      );

    if (selectedElement.elementType === ElementTypes.ITEM_BANK)
      dispatch(processItemBankDelete(selectedElement.element as ItemBank));

    closeModalAction();
  };

  function getElementTitle(
    element: Item | Task | Exam | Assessment | TaskSubContent | Content | ItemBank | undefined
  ): string {
    if (!element) return "";
    if ("title" in element && typeof element.title === "string") {
      return element.title;
    }
    if ("name" in element && typeof element.name === "string") {
      return element.name;
    }
    return "";
  }

  const errorDeleteValidator = (): string | undefined => {
    let erroMsg: string | undefined = undefined;
    if (selectedElement.element?.id && selectedElement.elementType === "TASK") {
      erroMsg = validateDelete(selectedElement.element as Task, exams);
    }
    return erroMsg;
  };
  const deleteError = errorDeleteValidator();

  return (

    <Dialog
      open={open}
      sx={{ marginLeft: "auto", marginRight: "auto", width: "100%" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box p={1} mt={2}>
          <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "inherit" }}>
            <Typography align="left" fontWeight="600" ml={3}>
              {deleteError === undefined
                ? "Deseja realmente excluir este item?"
                : "Remoção Não Permitida!"}
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => closeModalAction()}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                color: theme.palette.grey[500],
                mr: 1
              })}
            >
              <CloseIcon />
            </IconButton>
          </div>

        </Box>
        <Box
          p={1}
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography align="left" ml={3} style={{ fontSize: "14px" }} mt={2}>
            {deleteError === undefined
              ? <span> Você está prestes a excluir o curso <b style={{ color: "red" }}>"{getElementTitle(selectedElement.element)}"</b> da sua árvore. Deseja realmente continuar? </span>
              : deleteError}
          </Typography>
        </Box>
        <Box ml={4} mb={5} mt={2}>

          {deleteError === undefined ? (
            <Button onClick={(e) => processDelection()} variant="contained" style={{ textTransform: "uppercase", marginRight: "8px" }}>
              Excluir
            </Button>
          ) : null}


          <Button onClick={(e) => closeModalAction()} variant="outlined" style={{ color: "black", border: "1px solid black", textTransform: "uppercase" }}>
            Cancelar
          </Button>

        </Box>
      </Box>
    </Dialog>
  );
}
