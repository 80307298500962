import { Switch as SwitchMui, SwitchProps as SwitchMuiProps } from '@mui/material'

import './styles.css'

type TSwitchProps = SwitchMuiProps & {
    labelLeft?: string;
    labelRight?: string;
}

export function Switch({ labelLeft, labelRight, ...rest }: TSwitchProps) {
    return (
        <div className='switch__container'>
            {
                labelLeft &&
                <span style={{  }}>{labelLeft}</span>
            }

            <SwitchMui {...rest} />

            {
                labelRight &&
                <span style={{  }}>{labelRight}</span>
            }
        </div>
    )
}