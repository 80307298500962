import { Box, Button, Grid, Menu } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  gerarAssessmentFormData,
  gerarBankFormData,
  gerarElementoSelecionavel,
  gerarExamFormData,
  gerarItemFormData,
  gerarTaskFormData,
  gerarTaskSubcontentFormData,
} from "../utils/elementBuilders";

import { ReactComponent as Lixeira } from "assets/editiNavHeadImages/menu/delete.svg";
import { ReactComponent as Duplicate } from "assets/editiNavHeadImages/menu/copy.svg";
import { ReactComponent as Down } from "assets/editiNavHeadImages/menu/down.svg";
import { ReactComponent as Up } from "assets/editiNavHeadImages/menu/up.svg";

import { ReactComponent as Avaliacao } from "assets/editiNavHeadImages/contextButtomMenu/avaliacao.svg";
import { ReactComponent as Exame } from "assets/editiNavHeadImages/contextButtomMenu/exame.svg";
import { ReactComponent as Item } from "assets/editiNavHeadImages/contextButtomMenu/item.svg";
import { ReactComponent as Subenunciado } from "assets/editiNavHeadImages/contextButtomMenu/subenunciado.svg";
import { ReactComponent as Tarefa } from "assets/editiNavHeadImages/contextButtomMenu/tarefa.svg";
import { ReactComponent as Bank } from "assets/treeViewIcons/bank.svg";

import { KeyboardArrowDown } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  ElementTypes,
  IReorderItemRequest,
  IReorderRequest,
  Item as ItemType,
  SelectedElement,
  Task,
  TaskSubContent,
} from "services/admin/Interfaces/Types";
import { getAllTasks } from "store/reducers/Admin/Actions/Tasks/actions";
import { setSelectedElement } from "store/reducers/Admin/Actions/treeView/actions";
import { moveItemInsideTask } from "store/reducers/Admin/AsyncActions/Itens";
import { moveTaskInsideExam } from "store/reducers/Admin/AsyncActions/Tasks";
import { updateSubContentBulk } from "store/reducers/task_subcontent/AsyncActions";
import { getTaskById } from "../utils/elementGetters";
import {
  reorderItemInsideTask,
  reorderSubContentInsideTask,
} from "../utils/treeUtils";
import ContextualMenuListItem from "./ContextualMenuListItem";
import EditNavHeadButton from "./EditNavHeadButton";

interface IEditNavHead {
  onClickDelete: any;
  activeTab: string;
}

export default function EditNavHead({
  onClickDelete,
  activeTab,
}: IEditNavHead) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [reorderElements, setReorderElements] = useState<
    IReorderRequest | undefined
  >();

  const [subcontentReorder, setSubcontentReorder] = useState<
    TaskSubContent[] | undefined
  >();

  const tasks: Task[] = useSelector((state: any) => state.admin).tasks;

  useEffect(() => {
    const saveTaskOrder = setTimeout(() => {
      if (reorderElements) {
        dispatch(moveItemInsideTask(reorderElements));
        setReorderElements(undefined);
      }
    }, 4000);
    return () => clearTimeout(saveTaskOrder);
  }, [reorderElements]);

  useEffect(() => {
    const saveSubcontentOrder = setTimeout(() => {
      if (subcontentReorder) {
        dispatch(updateSubContentBulk(subcontentReorder as TaskSubContent[]));
        setSubcontentReorder(undefined);
      }
    }, 4000);
    return () => clearTimeout(saveSubcontentOrder);
  }, [subcontentReorder]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedElement: SelectedElement = useSelector(
    (state: any) => state.admin
  ).selectedElement;

  const hasSelectedElement = () => {
    return (
      selectedElement.elementType !== undefined &&
      selectedElement.element !== undefined
    );
  };

  const checkSelectedElementType = (checkType: string) => {
    return selectedElement.elementType === checkType;
  };

  const initDataFormCreationTask = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.TASK,
          undefined,
          gerarTaskFormData(undefined),
          selectedElement.element?.id
        )
      )
    );
  };

  const initDataFormCreationItem = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.ITEM,
          undefined,
          gerarItemFormData(
            undefined,
            checkSelectedElementType("ITEM_BANK")
              ? undefined
              : (selectedElement.element as Task)
          ),
          undefined
        )
      )
    );
  };

  const initDataFormCreationSubcontent = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.TASK_SUBCONTENT,
          undefined,
          gerarTaskSubcontentFormData(
            undefined,
            selectedElement.element as Task
          ),
          undefined
        )
      )
    );
  };

  const initDataFormCreationExam = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.EXAM,
          undefined,
          gerarExamFormData(undefined, undefined),
          selectedElement.element?.id
        )
      )
    );
  };

  const initDataFormCreationAssessment = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.ASSESSMENT,
          undefined,
          gerarAssessmentFormData(undefined),
          undefined
        )
      )
    );
  };

  const initDataFormCreationBank = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.ITEM_BANK,
          undefined,
          gerarBankFormData(undefined),
          undefined
        )
      )
    );
  };

  const initDataFormCreationTaskBank = () => {
    handleClose();
    dispatch(
      setSelectedElement(
        gerarElementoSelecionavel(
          ElementTypes.TASK,
          undefined,
          gerarTaskFormData(undefined),
          undefined
        )
      )
    );
  };

  const moveSelectedElement = (direction: "UP" | "DOWN") => {
    if (hasSelectedElement() && checkSelectedElementType(ElementTypes.TASK)) {
      dispatch(
        moveTaskInsideExam(
          selectedElement.element as Task,
          selectedElement.parentElement as number,
          direction
        )
      );
    }

    if (hasSelectedElement() && checkSelectedElementType(ElementTypes.ITEM)) {
      let task: Task = getTaskById(
        tasks,
        (selectedElement.element as ItemType).task as number
      ) as Task;

      task = reorderItemInsideTask(
        task,
        (selectedElement.element as ItemType).id as number,
        direction
      );

      const reorderRequest: IReorderRequest = {
        items: task.items.map(
          (item) =>
            ({
              id: item.id as number,
              number: item.number,
            } as IReorderItemRequest)
        ),
      };
      setReorderElements(reorderRequest);
      refreshTaskList(task);
    }

    if (
      hasSelectedElement() &&
      checkSelectedElementType(ElementTypes.TASK_SUBCONTENT)
    ) {
      let task: Task = getTaskById(
        tasks,
        (selectedElement.element as ItemType).task as number
      ) as Task;

      task = reorderSubContentInsideTask(
        task,
        (selectedElement.element as TaskSubContent).id as number,
        direction
      );

      setSubcontentReorder(task.subcontents);
      refreshTaskList(task);
    }
  };

  const refreshTaskList = (task: Task) => {
    let copyTasks: Task[] = [...tasks];
    let indexTask: number = -1;
    copyTasks.forEach((entry, index) => {
      if (entry.id === task.id) indexTask = index;
    });

    copyTasks[indexTask as number] = task;
    dispatch(getAllTasks(copyTasks));
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="1rem"

        pl={2}
        pr={3}
      >
        <div>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
            sx={{ textTransform: "none", width: "100%", height: "42px" }}
          >
            Inserir
          </Button>
        </div>

        <EditNavHeadButton
          active={false}
          svgImage={<Duplicate />}
          toolTipText="Duplicar Elemento"
        />
        <EditNavHeadButton
          active={enableOrderButtom(selectedElement, activeTab as "1" | "2")}
          svgImage={<Up />}
          toolTipText="Mover Elemento"
          redirectPath="/editor"
          action={() => moveSelectedElement("UP")}
        />
        <EditNavHeadButton
          active={enableOrderButtom(selectedElement, activeTab as "1" | "2")}
          svgImage={<Down />}
          toolTipText="Mover Elemento"
          redirectPath="/editor"
          action={() => moveSelectedElement("DOWN")}
        />
        <EditNavHeadButton
          active={hasSelectedElement()}
          redirectPath={"/editor/"}
          action={onClickDelete}
          svgImage={<Lixeira />}
          toolTipText="Remover Elemento"
        />
      </Box>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          color: "var(--primary-regular)",
          "& .MuiPaper-root": {
            border: "1px solid var(--neutral-3)",
            borderRadius: "8px",
          },
        }}
      >
        <>
          {activeTab === "1" ? (
            <>
              <ContextualMenuListItem
                text="Avaliação"
                icon={<Avaliacao />}
                action={initDataFormCreationAssessment}
                redirectUrl="/editor/create/assessment"
              />
              <ContextualMenuListItem
                text="Exame"
                icon={<Exame />}
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.ASSESSMENT)
                  )
                }
              />
              <ContextualMenuListItem
                text="Novo Exame"
                action={initDataFormCreationExam}
                redirectUrl="/editor/create/exam"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.ASSESSMENT)
                  )
                }
              />
              <ContextualMenuListItem
                text="Carregar Exame"
                action={initDataFormCreationExam}
                redirectUrl="/editor/load/exam"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.ASSESSMENT)
                  )
                }
              />
              <ContextualMenuListItem
                text="Tarefa"
                icon={<Tarefa />}
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.EXAM)
                  )
                }
              />
              <ContextualMenuListItem
                text="Nova Tarefa"
                action={initDataFormCreationTask}
                redirectUrl="/editor/create/task"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.EXAM)
                  )
                }
              />
              <ContextualMenuListItem
                text="Carregar Tarefa"
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType(ElementTypes.EXAM)
                  )
                }
                redirectUrl="/editor/load/task"
              />
              <ContextualMenuListItem
                text="Item"
                icon={<Item />}
                action={initDataFormCreationItem}
                redirectUrl="/editor/create/item"
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("TASK"))
                }
              />
              <ContextualMenuListItem
                text="Sub-Enunciado"
                icon={<Subenunciado />}
                action={initDataFormCreationSubcontent}
                redirectUrl="/editor/create/subcontent"
                disabled={
                  !(hasSelectedElement() && checkSelectedElementType("TASK"))
                }
              />
            </>
          ) : (
            <>
              <ContextualMenuListItem
                text="Novo Banco"
                icon={<Bank />}
                action={initDataFormCreationBank}
                redirectUrl="/editor/create/itembank"
                disabled={false}
              />

              <ContextualMenuListItem
                text="Novo Item"
                icon={<Item />}
                action={initDataFormCreationItem}
                redirectUrl={
                  checkSelectedElementType("ITEM_BANK")
                    ? "/editor/create/bank/" +
                      selectedElement.element?.id +
                      "/item"
                    : "/editor/create/item"
                }
                disabled={
                  !(
                    hasSelectedElement() &&
                    (checkSelectedElementType("TASK") ||
                      checkSelectedElementType("ITEM_BANK"))
                  )
                }
              />

              <ContextualMenuListItem
                text="Tarefa"
                icon={<Tarefa />}
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType("ITEM_BANK")
                  )
                }
              />

              <ContextualMenuListItem
                text="Nova Tarefa"
                action={initDataFormCreationTaskBank}
                redirectUrl={
                  "/editor/create/bank/" + selectedElement.element?.id + "/task"
                }
                disabled={
                  !(
                    hasSelectedElement() &&
                    checkSelectedElementType("ITEM_BANK")
                  )
                }
              />
            </>
          )}
        </>
      </Menu>
    </div>
  );
}

function enableOrderButtom(selectedElement, tabControl: "1" | "2"): boolean {
  if (tabControl === "1") {
    return (
      selectedElement.element !== undefined &&
      selectedElement.element.id !== undefined &&
      (selectedElement.elementType === ElementTypes.TASK ||
        selectedElement.elementType === ElementTypes.ITEM ||
        selectedElement.elementType === ElementTypes.TASK_SUBCONTENT)
    );
  } else {
    return (
      selectedElement.element !== undefined &&
      selectedElement.element.id !== undefined &&
      selectedElement.elementType === ElementTypes.ITEM &&
      selectedElement.element.task !== null
    );
  }
}
