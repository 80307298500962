import { Assessment, AssessmentExam } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class AssessmentsServices {
  public static async registerAssessments(props: Assessment) {
    let obj: object = { ...props };
    delete obj["exams"];
    return api.post("/assessments/assessments/", JSON.stringify(obj), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async getAllAssessments(size: number) {
    return api.get("/assessments/assessments/", { params: { limit: size } });
  }

  public static async updateAssessments(props: Assessment) {
    let obj: object = { ...props };
    delete obj["exams"];
    return api.patch(
      "/assessments/assessments/" + props.id + "/",
      JSON.stringify(obj),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  public static async joinExamAssesment(idExam: number, idAssessment: number) {
    let obj: AssessmentExam = {
      id: undefined,
      exam: idExam,
      assessment: idAssessment,
    };
    return api.post("/assessments/assessmentexams/", JSON.stringify(obj), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async deleteAssessments(props: Assessment) {
    return api.delete("/assessments/assessments/" + props.id + "/");
  }

  public static async generateCluster(id: any) {
    return api.get("/assessments/run-clustering/" + id);
  }
}
