import React from "react";
import "./styles.scss";
type Props = {
  children: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
  [key: string]: any;
};
export const SquareButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, active = false, onClick, ...rest }, ref) => (
    <button
    {...rest}
      ref={ref}
      onClick={onClick}
      className={"square-button " + (active ? "active" : "inactive")}
    >
      {children}
    </button>
  )
);
