import { Button, Dialog, DialogContent, Icon, Typography } from "@mui/material";
import "./delete-modal.scss";
import SvgImage from "assets/icons/delete.svg";
import { IDialogDelete } from "components/DialogDelete";

export interface IDeleteModal extends IDialogDelete {
  data?: {
    code: string;
    name: string;
    id: number | string;
  };
}

function getContent(data?: IDeleteModal["data"]) {
  if (!data) return "";
  return `${data?.id} - ${data?.name} - ${data?.code}`;
}

export const DeleteModal: React.FC<IDeleteModal> = ({
  isOpen,
  onClose,
  data,
  onConfirm,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent className="delete-container">
        <header>
          <img src={SvgImage} alt="delete icon" />
          <h1>Você realmente deseja excluir está área do conhecimento?</h1>
        </header>
        <div className="content">{getContent(data)}</div>
        <footer>
          <Button size="large" onClick={onConfirm} variant="contained">
            EXCLUIR
          </Button>
          <Button
            size="large"
            color="info"
            variant="outlined"
            onClick={onClose}
            sx={{
              color: 'black',
              borderColor: 'black',
              '&:hover': {
                borderColor: 'black',
                color: 'black',
              }
            }}
          >
            CANCELAR
          </Button>
        </footer>
      </DialogContent>
    </Dialog>
  );
};
