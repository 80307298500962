import {
  Choice,
  Item,
  Task,
  SelectedElement,
  ItemFormData,
  TaskFormData,
  ExamFormData,
  Exam,
  AssessmentFormData,
  Assessment,
  TaskExam,
  ElementTypes,
  TaskSubContentFormData,
  TaskSubContent,
  Content,
  ContentFormData,
  ItemBankFormData,
  ItemBank,
} from "services/admin/Interfaces/Types";
import { getTaskById } from "./elementGetters";

export function gerarListaChoices(numeroItems: number): Choice[] {
  let lista: Choice[] = [];
  for (let i = 0; i < numeroItems; i++) {
    lista[i] = { id: undefined, content: "", correct: false, item: undefined };
  }
  return lista;
}

export function gerarElementoSelecionavel(
  elementType:
    | ElementTypes.TASK
    | ElementTypes.ITEM
    | ElementTypes.EXAM
    | ElementTypes.ASSESSMENT
    | ElementTypes.TASK_SUBCONTENT
    | ElementTypes.CONTENT
    | ElementTypes.ITEM_BANK
    | undefined,
  element:
    | Task
    | Item
    | Exam
    | Assessment
    | TaskSubContent
    | Content
    | ItemBank
    | undefined,
  formData:
    | ItemFormData
    | TaskFormData
    | ExamFormData
    | AssessmentFormData
    | TaskSubContentFormData
    | ContentFormData
    | ItemBankFormData
    | undefined,
  parentElement: number | ItemBank | undefined
): SelectedElement {
  let result: SelectedElement = {
    elementType: elementType,
    element: element,
    elementDataFormInitial: formData,
    elementDataForm: formData,
    parentElement: parentElement,
  };

  return result;
}
export function gerarTaskFormData(element): TaskFormData {
  if (element !== undefined) {
    let editTask: Task = element as Task;
    return {
      id: editTask.id,
      description: editTask.content,
      title: editTask.title,
      items: editTask.items,
      is_public: editTask.is_public,
      item_bank: editTask.item_bank,
    };
  } else {
    return {
      id: undefined,
      title: "",
      description: "",
      items: [] as Item[],
      is_public: false,
    };
  }
}

export function gerarTaskSubcontentFormData(
  element,
  taskRoot: Task | undefined
): TaskSubContentFormData {
  if (element) {
    return {
      id: element.id,
      content: element.content,
      position: element.position,
      task: element.task,
      title: element.title || "",
    };
  } else {
    return {
      position: 0,
      task: taskRoot ? (taskRoot.id as number) : 0,
      content: "",
      title: "",
    };
  }
}

export function gerarItemFormData(
  item: Item | undefined,
  task: Task | undefined
): ItemFormData {
  if (item !== undefined) {
    return {
      id: item.id as number,
      title: item.title,
      description: item.content,
      itemType:
        item.is_hybrid === true ? "H" : item.choices.length > 0 ? "M" : "A",
      taskId: item.task,
      choices: item.choices.length > 0 ? item.choices : gerarListaChoices(4),
      number: item.number === null ? 0 : item.number,
      is_hybrid: item.is_hybrid ? item.is_hybrid : false,
      is_public: item.is_public ?? false,
    };
  } else {
    return {
      id: undefined,
      title: "",
      description: "",
      itemType: "A",
      taskId: task !== undefined ? task.id : 0,
      choices: gerarListaChoices(4),
      number: undefined,
      is_hybrid: false,
      is_public: false,
    };
  }
}

export function gerarExamFormData(
  param: Exam | undefined,
  tasks: Task[] | undefined
): ExamFormData {
  if (param !== undefined)
    return {
      id: param.id,
      code: param.code,
      description: param.description,
      published: param.published,
      tasks: initExamTasks(param, tasks as Task[]),
      knowledge_area: param.knowledge_area,
      created_by: param.created_by,
      title: param.title ? param.title : "",
    };
  else
    return {
      id: undefined,
      code: "",
      description: "",
      published: false,
      tasks: [],
      knowledge_area: undefined,
      created_by: undefined,
      title: "",
    };
}

function initExamTasks(exam: Exam, tasks: Task[]): TaskExam[] {
  let values: TaskExam[] = [];
  for (let i = 0; i < exam.tasks.length; i++) {
    let temp: TaskExam = { ...exam.tasks[i] };
    let task: Task | undefined = getTaskById(tasks, exam.tasks[i].task);
    if (task !== undefined) {
      temp.taskObj = task;
    }
    values.push(temp);
  }
  return values;
}

export function gerarBankFormData(
  param: ItemBank | undefined
): ItemBankFormData {
  if (param) {
    return {
      id: param.id,
      name: param.name,
      created_by: param.created_by,
      updated_in: param.updated_in,
    };
  } else {
    return {
      name: "",
    };
  }
}

export function gerarAssessmentFormData(
  param: Assessment | undefined
): AssessmentFormData {
  if (param !== undefined)
    return {
      id: param.id,
      code: param.code,
      created_at: param.created_at as string,
      description: param.description,
      ends_at: param.ends_at,
      starts_at: param.starts_at,
      exam_time: param.exam_time,
      exams: param.exams,
      modified_at: param.modified_at as string,
      created_by: param.created_by,
      title: param.title === null || undefined ? "" : param.title,
      min_evaluators: param.min_evaluators ? param.min_evaluators : 0,
      min_transcribers: param.min_transcribers ? param.min_transcribers : 0,
    };
  else
    return {
      id: undefined,
      code: "",
      created_at: undefined,
      description: "",
      ends_at: new Date().toISOString(),
      starts_at: new Date().toISOString(),
      exam_time: 0,
      exams: [],
      modified_at: undefined,
      created_by: undefined,
      title: "",
      min_evaluators: 0,
      min_transcribers: 0,
    };
}

export function gerarContentFormData(
  param: Content | undefined,
  subcontentType: "task" | "item" | "subcontent" | "content" | undefined,
  parent: number | undefined
): ContentFormData {
  if (param !== undefined)
    return {
      id: param.id,
      title: param.title!,
      parent: param.parent,
      subcontent_type: param.subcontent_type,
      subcontents: param.subcontents ? param.subcontents : [],
      created_at: param.created_at,
      updated_at: param.updated_at,
      created_by: param.created_by,
      object: param.object,
      position: param.position,
      text: param.text,
    };
  else
    return {
      id: undefined,
      title: "",
      parent: parent ? parent : undefined,
      subcontent_type: subcontentType as
        | "task"
        | "item"
        | "subcontent"
        | "content",
      subcontents: [],
      object: undefined,
      position: undefined,
      text: undefined,
    };
}
