import { Button, Grid, TextField } from "@mui/material";
import { SquareButton } from "components/SquareButton";
import { useState } from "react";

import { ReactComponent as Search } from "assets/editiNavHeadImages/menu/search.svg";
import { SquareSvg } from "components/SquareButton/square-svg";

interface ISearchTreeFilter {
  callBackFilterFuction: any;
}

const label = "Pesquise pelo nome...";
export default function FindBar(props: ISearchTreeFilter) {
  const [searchText, setSearchText] = useState("");

  return (
    <Grid
      container
      spacing={2}
      paddingLeft={2}
      paddingTop={3}
      paddingRight={3}
      paddingBottom={1}
    >
      <Grid item xs={10}>
        <TextField
          placeholder={label}
          title={label}
          variant="outlined"
          fullWidth={true}
          size="small"
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ height: "42px" }}
        />
      </Grid>
      <Grid item xs={2}>
        <SquareButton active>
          <SquareSvg>
            <Search />
          </SquareSvg>
        </SquareButton>
      </Grid>
    </Grid>
  );
}
