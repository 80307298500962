import {
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { Box, width } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Ability,
  AbilityEdge,
  AbilitySerie,
  Knowledge,
  KnowledgeArea,
  Page,
  SimpleAbilitySerie,
} from "services/admin/Interfaces/Types";
import {
  createAbility,
  fetchAbility,
  fetchAllAbility,
  removeAbility,
  updateAbility,
} from "store/reducers/abilities/AsyncActions";
import { getKnowledgesArea } from "store/reducers/Admin/Actions/KnowledgeArea/action";
import { findKnowledges } from "store/reducers/Admin/AsyncActions/KnowledgesArea";
import CardBase from "./CardBase";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { validateFormData } from "../validators/abilityValidator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { SET_DATA_FORM } from "store/reducers/abilities/Actions";
import { AddCircle, Bookmark } from "@mui/icons-material";
import ModalAbilitySerie from "../dialogComponents/DialogAbilitySerie";
import {
  RESET_MODAL_SIMPLE_ABILITY_SERIE,
  SET_MODAL_SIMPLE_ABILITY_SERIE,
} from "store/reducers/Serie_Ability/Actions";
import { fetchAllKnowledge } from "store/reducers/knowledges/AsyncActions";
import { DialogDelete, IDialogDelete } from "components/DialogDelete";
import { ptBR } from "@mui/x-data-grid/locales";
import { InputMarkdown } from "components/InputMarkdown";
import { AddBNCCModal } from "../dialogComponents/AddBNCCModal";
import { add } from "lodash";

const emptyAbility: Ability = {
  name: "",
  code: "",
  parent: 0,
  knowledge: 0,
  description: "",
};

const buttonStyle = {
  background: "white",
  color: "#1D2432",
  borderColor: "#CACDD5",
};

function buildTableReader(
  deleteFunction,
  editFunction,
  initModalSeriesFuntion,
  openAddBNCC: (data: Ability) => void
): GridColDef[] {
  const defColumn: GridColDef[] = [
    { field: "id", headerName: "Id", width: 90 },
    { field: "code", headerName: "Code", width: 100 },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "description", headerName: "Descricão", width: 200 },
    {
      field: "knowledgeName",
      headerName: "Conhecimento",
      width: 200,
    },
    {
      field: "parentName",
      headerName: "Habilidade Pai",
      width: 200,
    },
    {
      field: "edit",
      headerName: "Editar",
      width: 150,
      renderCell: (param) => {
        return (
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => {
              editFunction(param);
            }}
            startIcon={<EditIcon />}
          >
            <Typography>Editar</Typography>
          </Button>
        );
      },
    },
    {
      field: "series",
      headerName: "Séries",
      width: 100,
      renderCell: (param) => {
        return (
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => {
              initModalSeriesFuntion(param);
            }}
          >
            <Bookmark />
          </Button>
        );
      },
    },
    {
      field: "bncc",
      headerName: "BNCC",
      width: 100,
      renderCell: (param) => {
        return (
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => {
              openAddBNCC(param.row);
            }}
          >
            <AddCircle />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Excluir",
      width: 100,
      renderCell: (param) => {
        return (
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => {
              deleteFunction(param);
            }}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  return defColumn;
}

const CardRegisterAbility = (props) => {
  const dispatch = useDispatch();
  const [allAbilities, setAllAbilities] = useState([] as Ability[]);
  const dataForm = useSelector((state: any) => state.abilities).dataForm;
  const [currentPage, setCurrentPage] = useState(0);
  const [knowledges, setKnowledges] = useState([] as Knowledge[]);

  const [addBNCCModal, setAddBNCCModal] = useState<{
    open: boolean;
    ability?: Ability;
  }>({
    open: false,
    ability: undefined,
  });

  const [dataSeriesDialog, setDataSeriesDialog] = useState({
    opened: false,
    ability: {} as Ability,
  });

  const [openDialogDeleteAbility, setOpenDialogDeleteAbility] =
    useState<IDialogDelete>({
      isOpen: false,
      onClose: () => {},
      onConfirm: () => {},
    });

  const knowledgeAreas: Page<KnowledgeArea> = useSelector(
    (state: any) => state.admin
  ).knowledgeAreas;

  const abilities: Page<Ability> = useSelector(
    (state: any) => state.abilities
  ).currentPage;

  useEffect(() => {
    dispatch(fetchAbility(5, 0, undefined));
    dispatch(findKnowledges());
    fetchAllKnowledge().then((response: any) => {
      setKnowledges(response.data.results);
    });

    fetchAllAbility().then((response: any) => {
      setAllAbilities(response.data.results);
    });
  }, [dispatch]);

  const handleDataForm = (field, value) => {
    const newData: Ability = { ...dataForm };
    newData[field] = value;
    dispatch(SET_DATA_FORM(newData));
  };

  const changePage = (e) => {
    setCurrentPage(e);
    dispatch(fetchAbility(5, 5 * e, undefined));
  };

  const refreshAbilities = () => {
    dispatch(fetchAbility(5, 5 * currentPage, undefined));
  };

  const postSubmitAction = () => {
    dispatch(SET_DATA_FORM(emptyAbility));
  };

  const buildPageData = (): Ability[] => {
    let abilitiesNew: Ability[] = [];
    if (knowledges && allAbilities) {
      abilities.results.forEach((ab) => {
        let newAb = { ...ab };
        const parentDescription: string | undefined = undefined;
        if (newAb.parent) {
          newAb.parentName = allAbilities.find(
            (a) => a.id === newAb.parent
          )?.name;
        }
        newAb.knowledgeName = knowledges.find(
          (kn) => kn.id === newAb.knowledge
        )?.name;

        abilitiesNew.push(newAb);
      });
    }
    return abilitiesNew;
  };

  let pageData: Ability[] = buildPageData();

  const generateSelectKnowledges = () => (
    <Select
      value={dataForm.knowledge}
      label="Conhecimento *"
      sx={{ background: "white" }}
      onChange={(e) => handleDataForm("knowledge", e.target.value)}
      defaultValue={0}
    >
      <MenuItem key={"list_knowledges_0"} value={0}>
        {"Selecione um Conhecimento"}
      </MenuItem>
      {knowledges != undefined
        ? knowledges.map((i, key) => (
            <MenuItem key={"list_knowledges_area_" + key} value={i.id}>
              {i.name}
            </MenuItem>
          ))
        : null}
    </Select>
  );

  const generateSelectParent = () => (
    <Select
      value={dataForm.parent}
      label="Abilidade Pai"
      sx={{ background: "white" }}
      onChange={(e) => handleDataForm("parent", e.target.value)}
      defaultValue={0}
    >
      <MenuItem key={"list_habilidade_0"} value={0}>
        {"Habilidade"}
      </MenuItem>
      {allAbilities != undefined
        ? allAbilities.map((i, key) => (
            <MenuItem key={"list_parent" + key} value={i.id}>
              {i.name}
            </MenuItem>
          ))
        : null}
    </Select>
  );

  const handleRegister = () => {
    validateFormData(dataForm);
    if (dataForm.id) {
      dispatch(updateAbility(dataForm, currentPage));
    } else {
      dispatch(createAbility(dataForm, currentPage));
    }
    postSubmitAction();
  };

  const close = () => {
    setOpenDialogDeleteAbility({
      isOpen: false,
      onClose: () => {},
      onConfirm: () => {},
    });
  };

  // Build actions functions
  const deleteFunction = (dataTable: Ability) => {
    setOpenDialogDeleteAbility({
      isOpen: true,
      onClose: () => {
        close();
      },
      onConfirm: () => {
        dispatch(removeAbility(dataTable));
        close();
      },
    });
  };

  const editFunction = (dataTable: GridRenderCellParams<any, any, any>) => {
    dispatch(SET_DATA_FORM(dataTable.row));
  };

  const initModalSeriesFuntion = (
    dataTable: GridRenderCellParams<any, any, any>
  ) => {
    const data = { ...dataSeriesDialog };
    data.opened = true;
    data.ability = dataTable.row;
    dispatch(
      SET_MODAL_SIMPLE_ABILITY_SERIE(
        dataTable.row.series as SimpleAbilitySerie[]
      )
    );
    setDataSeriesDialog(data);
  };

  const openAddBNCC = (data: Ability) => {
    setAddBNCCModal({
      ability: data,
      open: true,
    });
  };

  // BNCC modal
  const onCloseAddBNCC = () => {
    setAddBNCCModal({
      ability: undefined,
      open: false,
    });
  };
  return (
    <CardBase
      title={"Gerenciamento de Habilidades"}
      showActionButtons={false}
      dataForm={dataForm}
    >
      <Grid container spacing={1} paddingTop={1}>
        <Grid item xs={3}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Código"
              value={dataForm.code}
              onChange={(e) => handleDataForm("code", e.target.value)}
              sx={{ background: "white" }}
              inputProps={{ maxLength: 10 }}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label="Nome"
              value={dataForm.name}
              onChange={(e) => handleDataForm("name", e.target.value)}
              sx={{ background: "white" }}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel required>Conhecimento</InputLabel>
            {generateSelectKnowledges()}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <InputMarkdown
            required
            label="Descrição"
            textValue={dataForm.description}
            callBackValue={(e) => handleDataForm("description", e)}
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <FormControl fullWidth>
            <Button
              sx={{ p: 1, width: "100%" }}
              variant="contained"
              onClick={(e) => handleRegister()}
              disabled={
                !(
                  dataForm.name &&
                  dataForm.description &&
                  dataForm.code &&
                  dataForm.knowledge != 0
                )
              }
            >
              <Typography>Cadastrar</Typography>
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={3} md={2}>
          <FormControl fullWidth>
            <Button
              sx={{
                p: 1,
                width: "100%",
                background: "#EDF1FF",
                color: "#365BDC",
              }}
              variant="contained"
              onClick={(e) => postSubmitAction()}
            >
              <Typography>Cancelar</Typography>
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            columns={buildTableReader(
              deleteFunction,
              editFunction,
              initModalSeriesFuntion,
              openAddBNCC
            )}
            rowCount={abilities.count}
            rows={pageData}
            pageSize={5}
            rowsPerPageOptions={[5]}
            onPageChange={(e) => changePage(e)}
            pagination
            paginationMode="server"
          />
        </Grid>
      </Grid>

      <ModalAbilitySerie
        opened={dataSeriesDialog.opened}
        ability={dataSeriesDialog.ability as Ability}
        closeAction={() => {
          dispatch(RESET_MODAL_SIMPLE_ABILITY_SERIE());
          setDataSeriesDialog({ ...dataSeriesDialog, opened: false });
          refreshAbilities();
        }}
      ></ModalAbilitySerie>

      <AddBNCCModal
        abilities={addBNCCModal.ability ? [addBNCCModal.ability] : []}
        open={addBNCCModal.open}
        onCancel={onCloseAddBNCC}
      />

      <DialogDelete {...openDialogDeleteAbility} />
    </CardBase>
  );
};

export default CardRegisterAbility;
