import { Exam } from "services/admin/Interfaces/Types";
import api from "services/api";

export interface IAddTaskInExam {
  number: number;
  task: string;
  exam: string;
}

export default class ExamServices {
  public static async registerExam(props: Exam) {
    return api.post("/assessments/exams/", JSON.stringify(props), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async getAllExams(
    options: { assessment_id?: number; limit?: number } = {}
  ) {
    return api.get<any>("/assessments/exams/", {
      params: options,
    });
  }

  public static async getAllExamsWithParams(
    options: { limit?: number; title?: string; code?: string } = {}
  ) {
    return api.get<any>("/assessments/exams/", {
      params: options,
    });
  }

  public static async getExamById(id: number) {
    return api.get(`/assessments/exams/${id}/`);
  }

  public static async updateExam(props: Exam) {
    return api.patch(
      "/assessments/exams/" + props.id + "/",
      JSON.stringify(props),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  public static async deleteExam(props: Exam) {
    return api.delete("/assessments/exams/" + props.id + "/");
  }

  public static async addTaskInExam(props: IAddTaskInExam) {
    return api.post("/assessments/taskexams/", props);
  }
}
