import { Ability, Page } from "services/admin/Interfaces/Types";
import api from "services/api";

export namespace BNCCAbilityInterfaces {
  export interface Create {
    code: string;
    description: string;
    abilities: number[];
  }

  export interface List extends Omit<Create, "abilities"> {
    id: number;
    abilities: Ability[];
  }
}

export type RequestPagination = {
  page?: number;
  limit?: number;
};

export class BnccAbilityService {
  public static async create(data: BNCCAbilityInterfaces.Create) {
    return api.post("/matrices/bncc_abilities/", data);
  }

  public static async loadAll({ limit = 0, page = 0 }: RequestPagination) {
    return api.get<Page<BNCCAbilityInterfaces.List>>(
      "/matrices/bncc_abilities/",
      {
        params: {
          limit,
          offset: limit * page,
        },
      }
    );
  }

  public static async getByAbilityId(abilityId: number) {
    const { data } = await this.loadAll({ limit: 0 });

    const filteredData = data.results.filter((item) =>
      item.abilities.some((ability) => ability.id === abilityId)
    );

    return filteredData;
  }
}
