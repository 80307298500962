import { IReorderRequest, Item } from "services/admin/Interfaces/Types";
import api from "services/api";
export default class ItensServices {
  public static async fetchItem(props: any) {
    return api.get("/items/items/", { params: { ...props } });
  }

  public static async registerItem(props: Item) {
    return api.post("/items/items/", JSON.stringify(props), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async updateItem(props: Item) {
    return api.patch("/items/items/" + props.id + "/", JSON.stringify(props), {
      headers: { "Content-Type": "application/json" },
    });
  }

  public static async deleteItem(props: Item) {
    return api.delete("/items/items/" + props.id + "/");
  }

  public static async reorderItems(props: IReorderRequest) {
    return api.post("/items/items/reorder/", props);
  }
}
