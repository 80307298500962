import { Box } from "@mui/material";
import ColapsibleTreeNav from "components/Admin/sideMenuComponents/ColapsibleTreeNav";
import EditNavHead from "components/Admin/sideMenuComponents/EditNavHead";
import FindBar from "components/Admin/sideMenuComponents/FindBar";
import { useState } from "react";
import "./SideBar.scss";

const Sidebar = ({ onClickDelete = () => {} }) => {
  const [treeFilterString, setTreeFilterString] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  return (
    <Box
      className="container-lateral"
      sx={{
        height: "90vh",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          width: "100%",
        }}
      >
        <FindBar callBackFilterFuction={setTreeFilterString} />
        <EditNavHead onClickDelete={onClickDelete} activeTab={activeTab} />
        <ColapsibleTreeNav
          treeFilterString={treeFilterString}
          setTabControl={(value) => setActiveTab(value)}
          tabControl={activeTab}
        />
      </Box>
    </Box>
  );
};

export default Sidebar;
